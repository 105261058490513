import * as THREE from "three";
import $ from "jquery";
import solarSystem from "./index.js"
import * as TWEEN from "tween";
import {Vector2} from "three";

export class Planet {
    /**
     *
     * @param app {solarSystem} Основной класс
     * @param planetData {object}
     */
    constructor(app, planetData){
        this.app = app;
        this.planetData = {
            ...planetData,
            ...planetData.data[3]
        };

        this.object = new THREE.Object3D();
        this.object.castShadow = true;
        this.object.time = 0;
        this.object.name = this.planetData.names;
        this.object.type = 'p';
        this.object.Planet = this;

        this.timeSatellite = 0;

        this.meshPlanet = null;
        this.meshStrokeLines = new THREE.Object3D();
        this.meshSatellites = [];

        this.init();
    }
    init(){
        this.createObject();
        this.createLabel();
        this.createStrokeLines();
        if(this.planetData.satellites !== undefined && this.planetData.satellites !==null ){
            this.createSatellites();
        }


        this.object.update = () => {
            let x, y, z;
            const {rotatePlanet,dist,distPlus,distSum,data} = this.planetData;
            if(this.object.hasOwnProperty('$label')&&(!this.app.viewObject)){
                let $label = this.object.$label;
                let position = new THREE.Vector3();
                position.setFromMatrixPosition(this.object.matrixWorld);

                // Установить позиции камеры
                this.app.camera.updateMatrix();
                this.app.camera.updateMatrixWorld();
                // Установить направленный пучок света на камеры
                this.app.frustum.setFromProjectionMatrix(new THREE.Matrix4().multiplyMatrices(this.app.camera.projectionMatrix, this.app.camera.matrixWorldInverse));

                // Проверить вхождение объекта в пучок света
                if (!this.app.frustum.containsPoint(position)) {
                    $label.hide();
                } else {
                    $label.show();

                    position.project(this.app.camera);

                    position.x = Math.round((0.5 + position.x / 2) * (this.app.canvas.width));
                    position.y = Math.round((0.5 - position.y / 2) * (this.app.canvas.height));

                    $label.css({'top':position.y, 'left': position.x});
                }
            }

            // Анимация вращения планет и движения по орбите
            if(this.app.rotate > 1 && this.app.rotateMove > 0){
                this.object.rotation.y += rotatePlanet;

                x = Math.sin((this.object.time+distPlus)*distSum)*dist;
                y = 0;
                z = Math.cos((this.object.time+distPlus)*distSum)*dist;
                this.object.position.set(x, y, z);
                this.object.time += Math.PI/180*0.2;
            }

            if (this.app.rotate > 0 && this.app.rotateMove > 0 && this.meshSatellites.length > 0) {
                for (let i = 0; i < this.meshSatellites.length; i++) {
                    const itemMeshSatellite = this.meshSatellites[i];
                    const dataSatellite = itemMeshSatellite.data;

                    x = Math.sin(this.timeSatellite * dataSatellite.distPlus)*dataSatellite.dist;
                    y = dataSatellite.distZ;
                    z = Math.cos(this.timeSatellite * dataSatellite.distPlus)*dataSatellite.dist;

                    itemMeshSatellite.position.set(x, y, z);
                }

                this.timeSatellite += Math.PI/180*0.2;
            }

            if(this.meshStrokeLines.visible){
                this.lookAtStrokeLines()
            }
        };

        this.meshPlanet.onMouseEnter = () => {
            this.lookAtStrokeLines();
            this.setStrokeVisible(true)
        };

        this.meshPlanet.onMouseLeave = () => {
            this.setStrokeVisible(false);
        };

        this.meshPlanet.onMouseClick = () => {
            this.app.setUuidViewObject(this.meshPlanet.uuid);
            this.app.moveToObject(this.object, this.planetData);
        };

        this.app.solarsystem.add(this.object);
    }

    /**
     * Обновление для линий. Пqо сути постоянно направлять к камере
     */
    lookAtStrokeLines(){
        let x,y,z;
        x = this.app.camera.position.x;
        y = this.app.camera.position.y;
        z = this.app.camera.position.z;

        this.meshStrokeLines.lookAt(new THREE.Vector3(x,y,z));
    }

    /**
     * Получить объект
     * @returns {Object3D}
     */
    getObject(){
        return this.object
    }

    /**
     * Создание сферы
     * @param radius
     * @param texture
     * @returns {Mesh}
     */
    createMeshSphere(radius, texture){
        // Create mesh, texture
        let onTexture = this.app.textureLoader.load(texture);
        onTexture.anisotropy = 10;
        let geometry = new THREE.SphereGeometry(radius,24,24);
        let material = new THREE.MeshPhongMaterial({map:onTexture});

        return new THREE.Mesh(geometry,material);
    }

    /**
     * Создание сферы и применения текстур к ней
     */
    createObject(){
        let {num,radius,texture,names,rotatePlanet,dist,distPlus,distSum,data} = this.planetData;

        this.meshPlanet = this.createMeshSphere(radius, texture);
        this.object.add(this.meshPlanet)
    }

    /**
     * Создание подписи у планет
     */
    createLabel(){
        // Создание для элементов текстовых описаний
        let div = $(document.createElement('div'));
        div.addClass('label-planet');
        div.html(this.planetData.label);
        $(this.app.container).append(div);
        this.object.$label = div;
        this.object.$label.on('click', (e)=>{
            this.app.moveToObject(this.object, this.planetData);
        });
        this.object.positionLabel = new Vector2(0, 0)
    }

    /**
     * Добавление элемента обводки для планет
     */
    createStrokeLines(){
        let line,
            sizeBox = new THREE.Box3().setFromObject(this.meshPlanet).getSize(),
            halfBoxX = sizeBox.x/2+25,
            halfBoxY = sizeBox.y/2+25;

        // Нижний левый угол
        line = Planet.createAngle(-halfBoxX, -halfBoxY, "left", "top");
        this.meshStrokeLines.add(line);
        // Верхний левый угол
        line = Planet.createAngle(-halfBoxX, halfBoxY, "left", "bottom");
        this.meshStrokeLines.add(line);
        // Нижний првый угол
        line = Planet.createAngle(halfBoxX, -halfBoxY, "right", "top");
        this.meshStrokeLines.add(line);
        // Верхний правый угол
        line = Planet.createAngle(halfBoxX, halfBoxY, "right", "bottom");
        this.meshStrokeLines.add(line);

        // Добавить элемент к объекту
        this.meshStrokeLines.visible = false;
        this.object.add(this.meshStrokeLines);
    }

    /**
     * Создание спутников планеты
     */
    createSatellites(){
        const {satellites} = this.planetData;
        if(satellites.length === 0)
            return false;

        for (let i = 0; i < satellites.length; i++) {
            let itemSatellite = satellites[i];
            const { radius, texture } = itemSatellite;
            let meshSatellite = this.createMeshSphere(radius, texture);
            meshSatellite.castShadow = true;

            meshSatellite.data = itemSatellite;

            meshSatellite.onMouseClick = () => {
                this.app.setUuidViewObject(meshSatellite.uuid);
                this.app.moveToObject(meshSatellite, meshSatellite.data);
            };

            this.meshSatellites.push(meshSatellite);
            this.object.add(meshSatellite);
        }
    }

    /**
     * Установка видимости обводки планет
     * @param bool
     */
    setStrokeVisible(bool){
        this.meshStrokeLines.visible = bool
    }

    /**
     * Создание линий для обводки
     * @param x
     * @param y
     * @param directionX
     * @param directionY
     * @returns {Line}
     */
    static createAngle(x, y, directionX, directionY) {
        const size = 25;
        let materialLine = new THREE.LineBasicMaterial( { color: 0xffffff } );
        let points = [],geometryLine,line;

        points.push( new THREE.Vector3( x, y, 0 ) );

        switch(directionX){
            case "left":
                points.push( new THREE.Vector3( x+size, y, 0 ) );
                break;
            case "right":
                points.push( new THREE.Vector3( x-size, y, 0 ) );
                break;
        }

        points.push( new THREE.Vector3( x, y, 0 ) );

        switch(directionY){
            case "top":
                points.push( new THREE.Vector3( x, y+size, 0 ) );
                break;
            case "bottom":
                points.push( new THREE.Vector3( x, y-size, 0 ) );
                break;
        }

        geometryLine = new THREE.BufferGeometry().setFromPoints( points );

        line = new THREE.Line(geometryLine, materialLine);
        return line
    }
}