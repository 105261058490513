import * as THREE from "three";
import solarSystem from './index'

import sun_surface from './texture/sun_surface.jpg'
import star_colorshift from './texture/star_colorshift.png'

import vertexSurf from 'raw-loader!glslify-loader!./shader/vertexSurf.glsl'
import fragmentSurf from 'raw-loader!glslify-loader!./shader/fragmentSurf.glsl'

// import dshaVcorhalo from 'raw-loader!glslify-loader!./shader/dshaVcorhalo.glsl'
// import dshaFhalo from 'raw-loader!glslify-loader!./shader/dshaFhalo.glsl'
// import dshaFcor from 'raw-loader!glslify-loader!./shader/dshaFcor.glsl'

export class Sun {
    /**
     *
     * @param app {solarSystem} Основной класс
     * @param data {object}
     */
    constructor(app, data){
        this.app = app
        this.data = data

        this.object = new THREE.Object3D()
        this.object.time = 0;
        this.object.name = this.data.names;
        this.object.type = 'l';
        this.object.Sun = this

        // настройки для солнца
        this.setting = Object.assign({
            color: "ffffff",
            corona: false
        }, this.data.setting)

        this.colorHex = new THREE.Color().setHex('0x'+this.setting.color);

        // Объект солнца с шейдером (обновляется)
        this.meshSun = null

		this.init()
    }

    /**
	 * Инициализация класса
     */
    init(){
    	// создание солнца
        this.createObjectSun()

        this.object.update = () => {
            // this.uniSurf.time.value = this.uniHalo.time.value = this.uniFlare.time.value = this.time;
            // this.uniSurf.time.value = this.uniHalo.time.value = this.time;

            // анимация для солнца и шейдера
            this.meshSun.rotation.x += 0.0002;
            this.meshSun.rotation.y += 0.0003;
            this.meshSun.uniforms.time.value = this.object.time;

            // this.children[1].lookAt(new THREE.Vector3(selfModule.camera.position.x,selfModule.camera.position.y,selfModule.camera.position.z));
            // this.children[2].lookAt(new THREE.Vector3(selfModule.camera.position.x,selfModule.camera.position.y,selfModule.camera.position.z));

            this.object.time += 0.04;
        };
    	// создание текстур
		// this.createTextures()

        this.meshSun.onMouseClick = () => {
            this.app.setUuidViewObject(this.meshSun.uuid);
            this.app.moveToObject(this.object, this.data);
        };

        this.app.solarsystem.add(this.object);
	}

    /**
     * Получить объект
     */
    getObject(){
        return this.object
    }

    /**
	 * Создание текстур для солнца
     */
    createTextures(){
        // this.solarflareTexture = this.textureLoader.load(this.pathReference+'sun/solarflare.png');
        // this.sunHaloTexture = this.textureLoader.load(this.pathReference+'sun/sun_halo.png');
        // this.sunHaloColorTexture = this.textureLoader.load(this.pathReference+'sun/halo_colorshift.png');
        // this.sunCoronaTexture = this.textureLoader.load(this.pathReference+'sun/corona.png');
	}

    /**
	 * Создание объекта солнца и применение текстур (шейлеров) к объекту
     */
    createObjectSun() {
        const { radius } = this.data
        // Создание основной текстуры для солнца
        let sunTexture = this.app.textureLoader.load(this.app.settings.pathPublic+'texture/sun_surface.jpg');
        let sunColorLookupTexture = this.app.textureLoader.load(this.app.settings.pathPublic+'texture/star_colorshift.png');
        sunTexture.anisotropy = 20;
        sunTexture.wrapS = sunTexture.wrapT = THREE.RepeatWrapping;
        let uniforms = {
            texturePrimary:{type: "t", value: sunTexture},
            textureColor:{type: "t", value: sunColorLookupTexture},
            time:{type: "f", value: 0},
            textR:{type:"f",value: this.colorHex.r},
            textG:{type:"f",value: this.colorHex.g},
            textB:{type:"f",value: this.colorHex.b}
        };

        let surfaceGeo = new THREE.SphereGeometry(radius,60,30);
        let sunShaderMaterial = new THREE.ShaderMaterial({
            uniforms:uniforms,
            vertexShader: vertexSurf,
            fragmentShader: fragmentSurf,
        });

        this.meshSun = new THREE.Mesh(surfaceGeo, sunShaderMaterial);
        this.meshSun.uniforms = uniforms

        this.object.add(this.meshSun)
    }
    // Создание солнца
    // makeSun(options){
    //     var {radius, names} = options;
    //     var cHexColor = new THREE.Color().setHex('0x48cddc');
    //
    //     // var uniFlare = {
    //     // 	texturePrimary:{type: "t", value: solarflareTexture},
    //     // 	time:{type: "f", value: 0},
    //     // 	textR:{type:"f",value:cHexColor.r},
    //     // 	textG:{type:"f",value:cHexColor.g},
    //     // 	textB:{type:"f",value:cHexColor.b}
    //     // };
    //
    //     var uniHalo = {
    //         texturePrimary:{type: "t", value: selfModule.sunHaloTexture},
    //         textureColor:{type: "t", value: selfModule.sunHaloColorTexture},
    //         time:{type: "f", value: 0 },
    //         textR:{type:"f",value:cHexColor.r},
    //         textG:{type:"f",value:cHexColor.g},
    //         textB:{type:"f",value:cHexColor.b}
    //     };
    //     // var uniCorona = {
    //     // 	texturePrimary:{type: "t", value: sunCoronaTexture},
    //     // 	textR:{type:"f",value:cHexColor.r},
    //     // 	textG:{type:"f",value:cHexColor.g},
    //     // 	textB:{type:"f",value:cHexColor.b}
    //     // };
    //
    //     var sun = new THREE.Object3D();
    //
    //     // Текстура плавного огня на солнце
    //     var starSurface = selfModule.makeStarSurface(radius,uniSurf);
    //     starSurface.name = names;
    //     starSurface.dtype = 'l';
    //     starSurface.dnum = 0;
    //     sun.add(starSurface);
    //     sun.uniSurf = uniSurf;
    //
    //     // Ореол
    //     // var starHalo = makeStarHalo(radius,uniHalo);
    //     // starHalo.name = 'dstarhalo';
    //     // sun.add(starHalo);
    //     // sun.uniHalo = uniHalo;
    //     // Свечение
    //     // var starGlow = makeStarGlow(radius,uniCorona);
    //     // starGlow.name = 'dstarglow';
    //     // sun.add(starGlow);
    //     // sun.uniCorona = uniCorona;
    //     // Кольца на солнце
    //     // var solarflare = makeSolarflare(radius,uniFlare);
    //     // sun.solarflare = solarflare;
    //     // solarflare.name = names;
    //     // solarflare.dtype = 'l';
    //     // solarflare.dnum = 0;
    //     // sun.add(solarflare);
    //     // sun.uniFlare = uniFlare;
    //
    //     sun.time = 0;
    //     // Обводка
    //     // var lineSegments = new THREE.LineSegments( sun, new THREE.LineDashedMaterial( { color: 0xffaa00, dashSize: 3, gapSize: 1 } ) );
    //     // lineSegments.computeLineDistances();
    //     // sun.add( lineSegments );
    //
    //     sun.update = function(){
    //         // this.uniSurf.time.value = this.uniHalo.time.value = this.uniFlare.time.value = this.time;
    //         // this.uniSurf.time.value = this.uniHalo.time.value = this.time;
    //         this.uniSurf.time.value = this.time;
    //         this.children[0].rotation.x+=0.0002;
    //         this.children[0].rotation.y+=0.0003;
    //         // this.children[1].lookAt(new THREE.Vector3(selfModule.camera.position.x,selfModule.camera.position.y,selfModule.camera.position.z));
    //         // this.children[2].lookAt(new THREE.Vector3(selfModule.camera.position.x,selfModule.camera.position.y,selfModule.camera.position.z));
    //         this.time+=0.04;
    //     }
    //     selfModule.solarsystem.add(sun);
    //
    //     return starSurface
    // }

    // Добавление ореола
    // makeStarHalo(radius,uniforms){
    //     var haloGeo = new THREE.PlaneGeometry(radius*3.2,radius*3.2);
    //     var sunHaloMaterial = new THREE.ShaderMaterial({
    //         uniforms:uniforms,
    //         fragmentShader: document.getElementById('dshaFhalo').textContent,
    //         vertexShader: document.getElementById('dshaVcorhalo').textContent,
    //         transparent: true,
    //         polygonOffset: true,
    //         polygonOffsetFactor: 1,
    //         polygonOffsetUnits: 100,
    //         depthWrite: false,
    //         blending: THREE.CustomBlending,
    //         blendEquation: THREE.AddEquation,
    //         blendSrc: THREE.OneFactor,
    //         blendDst: THREE.OneFactor
    //     });
    //     return new THREE.Mesh(haloGeo,sunHaloMaterial);
    // }
    // // Добавляет к звезде сияния звезды
    // makeStarGlow(radius,uniforms){
    //     var glowGeo = new THREE.PlaneGeometry(radius*18,radius*18);
    //     var sunGlowMaterial = new THREE.ShaderMaterial({
    //         uniforms:uniforms,
    //         fragmentShader:document.getElementById('dshaFcor').textContent,
    //         vertexShader:document.getElementById('dshaVcorhalo').textContent,
    //         transparent: true,
    //         polygonOffset: true,
    //         polygonOffsetFactor: 1,
    //         polygonOffsetUnits: 100,
    //         depthWrite: false,
    //         blending: THREE.CustomBlending,
    //         blendEquation: THREE.AddEquation,
    //         blendSrc: THREE.OneFactor,
    //         blendDst: THREE.OneFactor
    //     });
    //     return new THREE.Mesh(glowGeo,sunGlowMaterial);
    // }
}