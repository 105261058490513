import * as THREE from 'three'
import * as TWEEN from 'tween'
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import Stats from "stats-js"
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import {satellite} from './sputnik';
import $ from "jquery";
import { Object3D, Vector3 } from 'three';

import { MeshText2D, textAlign } from 'three-text2d'
import { Planet } from './planet'
import { Sun } from './sun'

import vertexGlowOrbit from 'raw-loader!glslify-loader!./shader/vertexGlowOrbit.glsl'
import fragmentGlowOrbit from 'raw-loader!glslify-loader!./shader/fragmentGlowOrbit.glsl'

export default class {
    constructor(elem, users){
        this.container;
		this.light;
		this.scene;

		this.camera;
		this.startPositionCamera = {x: 0, y: 700, z: 3000}

		this.controls;
		this.W;
		this.H;
		this.renderer;
		this.background;
		this.orbit;


		this.solarsystem;
		this.rotate=2;
		this.rotateMove=1;
		this.viewObject=0;
		this.uuidViewObject=null;
		this.currentObject=null;

        this.lastTimeMsec= null

        this.pathShaider = "../shader/";
        this.pathTexture = "public/assets/textures/";
        this.pathReference = this.pathTexture+"reference/";

        this.updateFcts	= [];

        // TextureLoader
        this.textureLoader = new THREE.TextureLoader();

        this.raycaster = new THREE.Raycaster();
        this.mouse = new THREE.Vector2();

        // Frustum
        this.frustum = new THREE.Frustum();

        /**
         * @var mixed геометрический объект, на который навели
         */
        this.currentMoveObject = null;

        /**
         * @var mixed канвас
         */
        this.canvas;

        this.stats = new Stats();

        this.objects = [];

        this.objectsData;

        this.settings = {
            pathJson: "/public/assets/json/planets.json",
            pathPublic: "/public/solar.system/",
            pathBackgroundImage: "public/assets/textures/galaxy.jpg",
            isStats: true
        }

        this.now = Date.now();
        this.delta = Date.now();
        this.then = Date.now();
        this.interval = 1000/30;
    }

    /**
     * Инициализация модуля
     * @param params
     */
	init(params){
        this.settings = Object.assign(
            this.settings,
            params
        );

        // Инициализация базовой сцены и управление сценой
        this.initScene()

        // Инициализация системы
        this.initSolarSystem()
	}
    /**
     * Инициализация окружения
     * Объекты: сцена, камера,класса управления камерой, фон, статистика, освещение
     */
    initScene(){
        this.container = document.getElementById('system');

        const W = this.container.offsetWidth;
        const H = this.container.offsetHeight;

        // Camera
        this.camera = new THREE.PerspectiveCamera(45,W/H,1,1000000);
        this.camera.position.set(this.startPositionCamera.x,this.startPositionCamera.y,this.startPositionCamera.z);

        // Scene
        this.scene = new THREE.Scene();
        this.scene.add(new THREE.AmbientLight(0xf1f1f1));

        // Light
        // light = new THREE.PointLight(0xf1f1f1,0.2,100000);
        // light.position.set(0,0,0);
        // light.castShadow = true;
        // light.shadow.mapSize.width = 1024;
        // light.shadow.mapSize.height = 1024;
        // light.shadow.camera.near = 2;
        // light.shadow.camera.far = 100;
        // this.scene.add(light);

        // Render
        // this.renderer = new THREE.WebGLRenderer({antialias:true,alpha:true,powerPreference:"low-power"});
        // this.renderer = new THREE.WebGLRenderer({precision:"mediump",antialias:true,alpha:true,powerPreference:"low-power"});
        this.renderer = new THREE.WebGLRenderer({precision:"lowp",antialias:true,alpha:true,powerPreference:"low-power"});
        this.renderer.setSize(W,H);
        this.container.appendChild(this.renderer.domElement);

        // Stats
        this.stats.showPanel(2); // 0: fps, 1: ms, 2: mb, 3+: custom
        if(this.settings.isStats){
            let div = document.createElement('div')
            div.appendChild(this.stats.dom)
            div.style.position = 'absolute';
            div.style.top = 0;
            div.style.left = 0;
            this.container.appendChild( div );
        }

        // Controls
        this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        this.controls.enableDamping = true;
        this.controls.dampingFactor = 0.05;
        this.controls.zoomSpeed = 0.5;
        this.controls.rotateSpeed = 0.3;
        this.controls.minDistance = 300;
        this.controls.maxDistance = 9000;
        this.controls.enablePan = true;

        // Canvas
        this.canvas = this.renderer.domElement;

        // Background
        this.background = new THREE.Mesh(
            new THREE.SphereGeometry(50000,32,32),
            new THREE.MeshBasicMaterial({
                map:this.textureLoader.load(this.settings.pathBackgroundImage),
                side:THREE.BackSide
            })
        )

        this.scene.add(this.background);

        // События
        window.addEventListener('resize', () => {
            this.canvasResize()
        }, false);

        this.renderer.domElement.addEventListener('mousemove',(e)=>{
            this.onMouseMove(e)
        });
        // Клик мышки
        this.renderer.domElement.addEventListener('click',(e)=>{
            this.onMouseClick(e)
        });
        // Тач на телефонаъ
        this.renderer.domElement.addEventListener('touchend',(e)=>{
            this.onTouchEnd(e)
        });
        // this.renderer.domElement.addEventListener('dblclick',onMouseDblClick);

        this.animate()
    }

    /**
     * Анимация
     */
    animate() {
        requestAnimationFrame(()=>{
            this.animate()
        });

        this.now = Date.now();
        this.delta = this.now - this.then;
        //update time dependent animations here at 30 fps
        if (this.delta > this.interval) {
            this.stats.begin();
            // this.scene.children[2].rotation.y-=0.00002;
            // Анимация системы
            if(this.solarsystem!==undefined){
                this.solarsystem.traverse(function(mesh){
                    if(mesh.update !== undefined){
                        mesh.update();
                    }
                });
            }

            TWEEN.update();
            this.controls.update();
            this.stats.end();

            // save now date
            this.then = this.now - (this.delta % this.interval);
        }

        this.render()
    }

    /**
     * Рендеринг
     */
    render() {
        this.renderer.clear();
        this.renderer.render(this.scene,this.camera);
    }

    /**
     * Пропорциональное уменьшение canvas
     */
    canvasResize() {
        this.camera.aspect = this.container.offsetWidth / this.container.offsetHeight;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(this.container.offsetWidth,this.container.offsetHeight);
    }


    /**
     * Инициализируем исполнение солнечной системы
     */
    initSolarSystem() {
        // Добавил класс загрузки
        $('body').addClass("loading")

        let request = $.getJSON(this.settings.pathJson)
        request.done((data) => {
            this.objectsData = data

            // Добавление 3D объектов на сцену
            this.add3dObjects()

            // Удалил класс загрузки
            $('body').removeClass("loading")
            $(document).on('keyup', (e)=>{
                if(e.which == 27){
                    this.moveToStartPoint()
                }
            });
            $(document).on('click', '.js_back', (e)=>{
                this.moveToStartPoint()
            });
        })
    }

    /**
     * Добавление 3D объектов
     * Объекты: орбиты, солнце, планеты, солнечная система
     */
    add3dObjects(){
        // Орбиты
        let dataOrbits = [];
        for (let i = 0; i < this.objectsData.length; i++) {
            const itemObjectData = this.objectsData[i];
            dataOrbits.push(itemObjectData[7])
        }

        this.orbit = this.makeOrbit(dataOrbits);

        // Объект системы
        this.solarsystem = new THREE.Object3D();

        // Развернул систему
        let vector3Rotation = new Vector3(0,45,-25)
        this.solarsystem.rotation.set(vector3Rotation.x, vector3Rotation.y, vector3Rotation.z);
        this.orbit.rotation.set(vector3Rotation.x, vector3Rotation.y, vector3Rotation.z)

        // Добавление объектов в систему
        for (let i = 0; i < this.objectsData.length; i++) {
            let object = null, itemObject = this.objectsData[i]

            switch (itemObject[0]) {
                case 'l':
                    let data = {
                        num:0,
                        radius: itemObject[4],
                        names: itemObject[1],
                        ...itemObject[3]
                    };

                    let sun = new Sun(this, data)
                    this.objects.push(sun.getObject())
                    break;
                case 'p':
                    let dataPlanet = {
                        num: i,
                        radius: itemObject[4],
                        texture: itemObject[5],
                        names: itemObject[1],
                        rotatePlanet: itemObject[6],
                        dist: itemObject[7],
                        distPlus: itemObject[8],
                        distSum: itemObject[9],
                        data:  itemObject
                    }

                    let planet = new Planet(this, dataPlanet)
                    this.objects.push(planet.getObject())
                    //
                    // let parentPlanet = object
                    // if(itemObject.hasOwnProperty('10')&&itemObject[10].length>0){
                    //     for (let s = 0; s < itemObject[10].length; s++) {
                    //         const sputnik = itemObject[10][s];
                    //
                    //         object = this.makeSputnik(parentPlanet, sputnik);
                    //         object.type = sputnik.type
                    //         object.data = sputnik
                    //         this.objects.push(object)
                    //     }
                    // }
                    break;
                case 's':
                    break;
            }
        }
        this.scene.add(this.solarsystem);
    }

    /**
     * Создание орбит
     * @param radius
     * @returns {Object3D}
     */
    makeOrbit(radius) {
        let materialOrbit,
            geometryOrbit,
            lineOrbit,
            segments = 64,
            objOrbit = new THREE.Object3D();

        // Шейдер свечения
        let customMaterial = new THREE.ShaderMaterial({
            uniforms:
                {
                    "c": { type: "f", value: 0.5 },
                    "p": { type: "f", value: 20.5 },
                    glowColor: { type: "c", value: new THREE.Color(0xffffff) },
                    viewVector: { type: "v3", value: this.camera.position }
                },
            vertexShader: vertexGlowOrbit,
            fragmentShader: fragmentGlowOrbit,
            side: THREE.BackSide,
            blending: THREE.AdditiveBlending,
            transparent: true,
        });

        if(radius.length){
            for (var i=0;i<radius.length;i++) {

                let object = new Object3D(),
                    material = new THREE.LineBasicMaterial( { color: 0xffffff, opacity: .2, transparent: true } ),
                    geometry = new THREE.CircleGeometry( radius[i], segments );

                // Remove center vertex
                geometry.vertices.shift();
                geometry.vertices.push(geometry.vertices[0])

                // Нарисовать орбиту
                let line = new THREE.Line(geometry, material)
                line.rotation.set(29.85, 0, 0)
                object.add(line)

                // Эффект свечения орбиты
                // let lineGlow = new THREE.Line(geometry, customMaterial.clone());
                // lineGlow.position.set(line.position.x,line.position.y,line.position.z);
                // lineGlow.rotation.set(line.rotation.x,line.rotation.y,line.rotation.z)
                // lineGlow.scale.multiplyScalar(1.2);
                // object.add(lineGlow);
                // object.glow = line

                // object.update = function(){
                // 	this.glow.material.uniforms.viewVector.value = new THREE.Vector3().subVectors( this.camera.position, this.glow.position );
                // }

                objOrbit.add(object);
            }
        }
        this.scene.add(objOrbit);

        return objOrbit
    }

    /**
     * Очищение объекта, хранящий текущий наведённый элемент
     */
    clearCurrentObjectMouseMove(){
        if(this.currentMoveObject!==null&&this.currentMoveObject.onMouseLeave !== undefined){
            this.currentMoveObject.onMouseLeave()
            this.currentMoveObject = null
        }
    }

    /**
     * Движение мышки по эоементу канвас
     * @param e
     * @returns {boolean}
     */
    onMouseMove(e) {
        // Если уже приближена планета
        if(this.viewObject)
            return false

        e.preventDefault();
        this.mouse.x = (e.clientX / window.innerWidth) * 2 - 1;
        this.mouse.y = - (e.clientY / window.innerHeight) * 2 + 1;

        // Класс обнаружения объекта, на которой навела мышка
        this.raycaster.setFromCamera(this.mouse,this.camera);
        var intersects = this.raycaster.intersectObjects(this.objects,true);
        if (intersects.length > 0 && intersects[0].object.name !== 'dstarhalo' && intersects[0].object.name !== 'dstarglow') {
            // Прекратить вращение планет
            this.rotateMove = 0;
            let object = intersects[0].object,
                name = intersects[0].object.name,
                x = (((this.mouse.x + 1) / 2) * window.innerWidth),
                y = (- ((this.mouse.y - 1) / 2) * window.innerHeight);

            // Очищаем текущее наведение
            this.clearCurrentObjectMouseMove()

            // Заносим текущий объект, на который навели, в хранилище текущего объекта
            this.currentMoveObject = object
            // Если у объекта есть логика по наведению
            if(object.onMouseEnter !== undefined){
                object.onMouseEnter();
            }

            // PlanetInfoMessage(name,(((this.mouse.x + 1) / 2) * window.innerWidth) + 25,(- ((this.mouse.y - 1) / 2) * window.innerHeight) - 25);

            // if(object.type == "s"){
            // 	$('#info_company').find('.js_text').html(`<a href="${object.data.small_description}">${object.data.name}</a>`)
            // }else{
            // 	$('#info_company').find('.js_text').html(`<a href="${object.data[2]}">${object.data[1]}</a>`)
            // }

            // if(object.type != "l"){
            // 	let left = (x-($('#info_company').width()))-75,
            // 		top = y - ($('#info_company').height()/2)

            // 	if(left<0){
            // 		left = x + 45
            // 	}
            // 	// showFullInfo('','flex',left,top);
            // }
            this.container.style.cursor = 'pointer';
        } else {
            // Очищаем текущее наведение
            this.clearCurrentObjectMouseMove()

            this.rotateMove = 1;
            this.PlanetInfoMessage('',0,0);
            // this.showFullInfo('','none');
            this.container.style.cursor = 'auto';
        }
    }

    /**
     * Установить UUID текущего элемента или значение null, если сброшено
     * @param {string|null} uuid
     */
    setUuidViewObject(uuid){
        this.viewObject = 0;
        this.uuidViewObject = uuid;
    }

    /**
     * Получить значение uuid текущего элемента
     * @returns {null|string}
     */
    getUuidViewObject(){
        if(this.uuidViewObject===null){
            return false;
        }
        return this.uuidViewObject;
    }

    /**
     * Событие клика по объект на canvas
     * @param e
     */
    onMouseClick(e) {
        e.preventDefault();
        this.mouse.x = (e.clientX / window.innerWidth) * 2 - 1;
        this.mouse.y = - (e.clientY / window.innerHeight) * 2 + 1;
        // продолжить события клика на элемент
        this.onClickTouch()
    }

    /**
     * Событие клика по объект на canvas в телефонах
     * @param e
     */
    onTouchEnd(e){
        e.preventDefault();

        this.mouse.x = (e.changedTouches[0].clientX / window.innerWidth) * 2 - 1;
        this.mouse.y = -(e.changedTouches[0].clientY / window.innerHeight) * 2 + 1;
        // продолжить события нажатия на экран смартфона
        this.onClickTouch()
    }

    /**
     * Событие клика по объектам. Поиск по курсору объектов
     * @returns {boolean}
     */
    onClickTouch(){
        this.raycaster.setFromCamera(this.mouse,this.camera);
        var intersects = this.raycaster.intersectObjects(this.objects,true);
        if (intersects.length > 0 && intersects[0].object.name !== 'dstarhalo' && intersects[0].object.name !== 'dstarglow') {
            // Получить текущий элемент
            let object = intersects[0].object
            // Если уже выбран этот элемент, то не приближать камеру снова
            let uuidCurrentView = this.getUuidViewObject()
            if(uuidCurrentView&&uuidCurrentView===object.uuid){
                return false
            }

            if(object.onMouseClick!==undefined){
                object.onMouseClick()
            } else {
                // Приблизить к планете
                this.moveToObject(object)
            }
        } else {
            // moveToStartPoint()
            // showShortInfo('','none',0,0);
        }
    }

    // Сообщение о объекте
    PlanetInfoMessage(msg,left,top){
        $('#planet-info-loader div').html(msg);
        $('#planet-info-loader').css({left:left,top:top});
    }
    // Детальное сообщение об объекта
    showFullInfo(msg,display,left,top){
        $('#info_company').css({display:display});
        if(left!==undefined&&top!==undefined){
            $('#info_company').css({left:left,top:top});
        }
    }

    /**
     * Приближение к элементу
     * @param object {Object3D}
     * @param data {object}
     * @param groupId
     */
    moveToObject(object,data, groupId) {
        this.viewObject = 1;

        // Скрыть текстовое описание планет
        for (let i = 0; i < this.objects.length; i++) {
            const object = this.objects[i];
            if(object.hasOwnProperty('$label')){
                object.$label.hide()
            }
        }

        // showShortInfo('','none',0,0);
        this.showFullInfo('','none');

        let x, y, z;
        let position = new Vector3()
        let oSize, vectorLenght, vectorLenght2
        let endPosition, targetPosition, endPositionLength, targetPositionLength

        TWEEN.removeAll();
        if(object!==undefined){
            if (object.type === 'b') {
                // var vector = new THREE.Vector3(this.solarsystem.children[dnum].children[groupId].position.x,this.solarsystem.children[dnum].children[groupId].position.y,this.solarsystem.children[dnum].children[groupId].position.z).applyMatrix4(this.solarsystem.children[dnum].matrixWorld);
                // var oposX = vector.x;
                // var oposY = vector.y;
                // var oposZ = vector.z;
            } else {
                position = object.getWorldPosition(position);
                x = position.x;
                y = position.y;
                z = position.z;
            }
        }

        // Получение векторов
        if (object.type === 'l') {
            this.rotate = 2;
            oSize = 250;
            endPositionLength = new THREE.Vector3(0,0,1250);
            targetPositionLength = new THREE.Vector3(0,0,0);
        } else {
            switch (object.type) {
                case "t":
                    oSize = Math.floor(this.solarsystem.children[dnum].children[0].children[0].geometry.boundingSphere.radius);
                    break;
                case "b":
                    oSize = Math.floor(this.solarsystem.children[dnum].children[groupId].geometry.boundingSphere.radius);
                    break;
                case "p":
                    oSize = Math.floor(object.children[0].geometry.boundingSphere.radius);
                    break;
                default:
                    oSize = Math.floor(object.geometry.boundingSphere.radius);
                    break;
            }

            endPosition = new THREE.Vector3(x,y,z);
            targetPosition = new THREE.Vector3(x,y,z);

            endPositionLength = endPosition.setLength((endPosition.length()-(oSize*5)));
            targetPositionLength = targetPosition.setLength((targetPosition.length()));
        }

        // Значений перехода
        let targetCamera, targetControls
        targetCamera = {x:endPositionLength.x+45,y:endPositionLength.y + 75,z:endPositionLength.z - 25};
        targetControls = {x:targetPositionLength.x,y:targetPositionLength.y,z:targetPositionLength.z};

        // Анимация для камеры
        let tweenControl = new TWEEN.Tween(this.controls.target)
            .to(targetControls, 1500)
            .easing(TWEEN.Easing.Sinusoidal.InOut);
        tweenControl.start()

        // Анимация
        let tween = new TWEEN.Tween(this.camera.position)
            .to(targetCamera,2000)
            .easing(TWEEN.Easing.Sinusoidal.InOut);

        tween.onStart(function(){
            if (object.type === 'p') {
                this.rotate = 1;
            }
            // if (object.type == 's' || object.type == 't' || object.type == 'r' || object.type == 'b') {this.rotate = 0;}
        });
        tween.onComplete(() => {
            let $infoCompany = $('#info_company');
            // Скрыть орбиты
            this.orbit.visible = false

            this.controls.minDistance = oSize * 2;

            $infoCompany.find('.js_logo').hide();
            data.link = data.link || "javascript:;";
            data.logo = data.logo || null;

            $infoCompany.find('.js_text').html(`<a href="${data.link}">${data.names}</a>`);

            if(data.logo != null){
                $infoCompany.find('.js_logo').attr('src', data.logo);
                $infoCompany.find('.js_logo').show()
            }

            setTimeout(()=>{
                this.currentObject = object;
                let pos = new THREE.Vector3(object.position.x, object.position.y, object.position.z);
                pos.project(this.camera);

                let widthHalf = window.innerWidth/2;
                let heightHalf = window.innerHeight/2;

                let x = ( pos.x * widthHalf ) + (widthHalf/2);
                let y = - ( pos.y * heightHalf ) + heightHalf;

                let width = $infoCompany.width();
                let height = $infoCompany.height();

                this.showFullInfo('','flex',(150),(heightHalf-(height/2)));
            }, 100)
        });
        tween.start();
    }

    /**
     * Вернуться к первоначальной точке
     */
    moveToStartPoint(){
        // Отобразить орбиты
        this.orbit.visible = true

        this.currentObject = null;

        this.showFullInfo('','none');
        this.rotate = 2;

        TWEEN.removeAll();

        let tweenControl = new TWEEN.Tween(this.controls.target)
            .to({x:0,y:0,z:0}, 1500)
            .easing(TWEEN.Easing.Sinusoidal.InOut)

        tweenControl.active = false
        
        let tween = new TWEEN.Tween(this.camera.position)
            .to(this.startPositionCamera,2000)
            .easing(TWEEN.Easing.Sinusoidal.InOut)
        
        tween.onComplete(()=>{
            this.controls.minDistance = 500;
            // Сбросить текущий элемент
            this.setUuidViewObject(null);
        });
        tween.onUpdate((e,d)=>{
            if(e>0.2&&!tweenControl.active){
                tweenControl.active = true
                tweenControl.start()
            }
        })

        tween.start();
        this.cameraRotate();
    }

    /**
     * Развернуть камеру кажется
     */
    cameraRotate() {
        let endRotation = new THREE.Euler().copy(this.camera.rotation);
        let qa = new THREE.Quaternion().copy(this.camera.quaternion);
        let qb = new THREE.Quaternion().setFromEuler(endRotation);
        let qm = new THREE.Quaternion();
        let delta = {t:0}
        let tweenCam = new TWEEN.Tween(delta)
            .to({t:1},2000)
            .easing(TWEEN.Easing.Sinusoidal.InOut);
        tweenCam.onUpdate(()=>{
            THREE.Quaternion.slerp(qa,qb,qm,delta.t);
            this.camera.quaternion.set(qm.x,qm.y,qm.z,qm.w);
        });
        tweenCam.start();
    }
}